import utils from "./utils/utils";

const StateService = (function(){
	const stateProviders = {};

	function getState(name){
		const provider = stateProviders[name];
		if(provider){
			return stateProviders[name].method();
		}else{
			const res = {};
			for(const i in stateProviders){
				if(!stateProviders[i].internal)
					utils.mixin(res, stateProviders[i].method(), true);
			}
			return res;
		}
	}

	function registerProvider(name, provider, internal){
		stateProviders[name] = { method: provider, internal: internal};
	}

	function unregisterProvider(name){
		delete stateProviders[name];
	}

	return {
		getState: getState,
		registerProvider: registerProvider,
		unregisterProvider: unregisterProvider
	};
});

export default StateService;

