export default function(scheduler){

scheduler.$keyboardNavigation.TimeSlot = function(from, to, section, movingDate){
	var state = scheduler.getState();
	var timeline = scheduler.matrix && scheduler.matrix[state.mode];

	if(!from){
		from = this.getDefaultDate();
	}

	if(!to){

		if(timeline){
			to = scheduler.date.add(from, timeline.x_step, timeline.x_unit);
		}else{
			to = scheduler.date.add(from, scheduler.config.key_nav_step, "minute");
		}

	}

	this.section = section || this._getDefaultSection();
	this.start_date = new Date(from);
	this.end_date = new Date(to);
	this.movingDate = movingDate || null;
};

scheduler.$keyboardNavigation.TimeSlot.prototype = scheduler._compose(
	scheduler.$keyboardNavigation.KeyNavNode,
	{
		_handlers:null,

		getDefaultDate: function(){
			var from;
			var state = scheduler.getState();
			var visibleTime = new Date(state.date);
			visibleTime.setSeconds(0);
			visibleTime.setMilliseconds(0);

			var nowTime = new Date();
			nowTime.setSeconds(0);
			nowTime.setMilliseconds(0);

			var timeline = scheduler.matrix && scheduler.matrix[state.mode];
			var showNowTime = false;
			if(visibleTime.valueOf() === nowTime.valueOf()){
				showNowTime = true;
			}

			if(timeline){
				if(showNowTime){
					if(timeline.x_unit === "day"){
						nowTime.setHours(0);
						nowTime.setMinutes(0);
					}else if(timeline.x_unit === "hour"){
						nowTime.setMinutes(0);
					}
					from = nowTime;
				}else{
					from = scheduler.date[timeline.name + "_start"](new Date(state.date));
				}

				from = this.findVisibleColumn(from);
			}else{
				from = new Date(scheduler.getState().min_date);
				if(showNowTime){
					from = nowTime;
				}
				from = this.findVisibleColumn(from);
				if(!showNowTime){
					from.setHours(scheduler.config.first_hour);
				}

				if(!scheduler._table_view){
					var dataContainer = scheduler.$container.querySelector(".dhx_cal_data");
					if(dataContainer.scrollTop){
						from.setHours(scheduler.config.first_hour + Math.ceil(dataContainer.scrollTop / scheduler.config.hour_size_px));
					}
				}
			}

			return from;
		},

		clone: function(timeslot){
			return new scheduler.$keyboardNavigation.TimeSlot(timeslot.start_date, timeslot.end_date, timeslot.section, timeslot.movingDate);
		},
		_getMultisectionView: function(){
			var state = scheduler.getState();
			var view;
			if(scheduler._props && scheduler._props[state.mode]) {
				view = scheduler._props[state.mode];
			}else if(scheduler.matrix && scheduler.matrix[state.mode]){
				view = scheduler.matrix[state.mode];
			}
			return view;
		},

		_getDefaultSection: function(){
			var section = null;
			var view = this._getMultisectionView();
			if(view && !section){
				section = this._getNextSection();
			}
			return section;
		},

		_getNextSection: function(sectionId, dir){
			var view = this._getMultisectionView();
			var currentIndex = view.order[sectionId];
			var nextIndex = currentIndex;
			if(currentIndex !== undefined){
				nextIndex =  currentIndex + dir;
			}else{
				nextIndex = (view.size && view.position) ? view.position : 0;
			}

			if(nextIndex < 0){
				nextIndex = 0;
			}
			var options = view.options || view.y_unit;

			if(nextIndex >= options.length){
				nextIndex = options.length - 1;
			}
			//nextIndex = nextIndex < 0 ? nextIndex = (view.options || view.y_unit).length -1 : nextIndex;

			if(options[nextIndex]){
				return options[nextIndex].key;
			}else{
				return null;
			}
		},


		isValid: function(){
			var state = scheduler.getState();
			var isInRange = !(this.start_date.valueOf() < state.min_date.valueOf() || this.start_date.valueOf() >= state.max_date.valueOf());

			if(!isInRange) return false;


			if(!this.isVisible(this.start_date, this.end_date)) return false;

			var view = this._getMultisectionView();

			if(view){
				return (view.order[this.section] !== undefined);
			}else{
				return true;
			}

		},

		fallback:function(){

			var defaultSlot = new scheduler.$keyboardNavigation.TimeSlot();
			if(!defaultSlot.isValid()){
				return new scheduler.$keyboardNavigation.DataArea();
			}else{
				return defaultSlot;
			}
		},

		getNodes: function(){
			return Array.prototype.slice.call(scheduler.$container.querySelectorAll(".dhx_focus_slot"));
		},
		getNode: function(){
			return this.getNodes()[0];
		},

		focus: function(){
			if(this.section && scheduler.getView() && scheduler.getView().smart_rendering && scheduler.getView().scrollTo && !scheduler.$container.querySelector(`[data-section-id="${this.section}"]`)){
				scheduler.getView().scrollTo({section: this.section});
			}
			scheduler.$keyboardNavigation.marker.render(this.start_date, this.end_date, this.section);
			scheduler.$keyboardNavigation.KeyNavNode.prototype.focus.apply(this);
			scheduler.$keyboardNavigation._pasteDate = this.start_date;
			scheduler.$keyboardNavigation._pasteSection = this.section;

		},
		blur: function(){
			scheduler.$keyboardNavigation.KeyNavNode.prototype.blur.apply(this);
			scheduler.$keyboardNavigation.marker.clear();
		},

		_modes: scheduler.$keyboardNavigation.SchedulerNode.prototype._modes,

		_getMode: scheduler.$keyboardNavigation.SchedulerNode.prototype.getMode,

		addMonthDate: function(date, dir, extend){
			var res;
			switch (dir){
				case "up":
					res = scheduler.date.add(date, -1, "week");
					break;
				case "down":
					res = scheduler.date.add(date, 1, "week");
					break;
				case "left":
					res = scheduler.date.day_start(scheduler.date.add(date, -1, "day"));
					res = this.findVisibleColumn(res, -1);
					break;
				case "right":
					res = scheduler.date.day_start(scheduler.date.add(date, 1, "day"));
					res = this.findVisibleColumn(res, 1);
					break;
				default:
					res = scheduler.date.day_start(new Date(date));
					break;
			}

			var state = scheduler.getState();
			if(date.valueOf() < state.min_date.valueOf() || (!extend && date.valueOf() >= state.max_date.valueOf())){
				res = new Date(state.min_date);
			}

			return res;
		},

		nextMonthSlot: function(slot, dir, extend){

			var start, end;
			start = this.addMonthDate(slot.start_date, dir, extend);

			start.setHours(scheduler.config.first_hour);
			end = new Date(start);
			end.setHours(scheduler.config.last_hour);
			return {start_date: start, end_date: end};
		},

		_alignTimeSlot: function(date, minDate, unit, step){
			var currentDate = new Date(minDate);
			while(currentDate.valueOf() < date.valueOf()){
				currentDate = scheduler.date.add(currentDate, step, unit);
			}

			if(currentDate.valueOf() > date.valueOf()){
				currentDate = scheduler.date.add(currentDate, -step, unit);
			}

			return currentDate;
		},

		nextTimelineSlot: function(slot, dir, extend){
			var state = scheduler.getState();
			var view = scheduler.matrix[state.mode];

			var startDate  = this._alignTimeSlot(slot.start_date, scheduler.date[view.name + "_start"](new Date(slot.start_date)), view.x_unit, view.x_step);
			var endDate = this._alignTimeSlot(slot.end_date, scheduler.date[view.name + "_start"](new Date(slot.end_date)), view.x_unit, view.x_step);

			if(endDate.valueOf() <= startDate.valueOf()){
				endDate = scheduler.date.add(startDate, view.x_step, view.x_unit);
			}
			var newPos = this.clone(slot);
			newPos.start_date = startDate;
			newPos.end_date = endDate;
			newPos.section = slot.section || this._getNextSection();


			switch (dir){
				case "up":
					newPos.section = this._getNextSection(slot.section, -1);
					break;
				case "down":
					newPos.section = this._getNextSection(slot.section, +1);
					break;
				case "left":
					newPos.start_date = this.findVisibleColumn(scheduler.date.add(newPos.start_date, -view.x_step, view.x_unit), -1);
					newPos.end_date = scheduler.date.add(newPos.start_date, view.x_step, view.x_unit);
					break;
				case "right":
					newPos.start_date = this.findVisibleColumn(scheduler.date.add(newPos.start_date, view.x_step, view.x_unit), 1);
					newPos.end_date = scheduler.date.add(newPos.start_date, view.x_step, view.x_unit);

					break;
				default:

					break;
			}

			if(newPos.start_date.valueOf() < state.min_date.valueOf() || newPos.start_date.valueOf() >= state.max_date.valueOf()){
				if(extend && newPos.start_date.valueOf() >= state.max_date.valueOf()){
					newPos.start_date = new Date(state.max_date);
				}else{
					newPos.start_date = scheduler.date[state.mode + "_start"](scheduler.date.add(state.date, dir == "left" ? -1 : 1, state.mode));
					newPos.end_date = scheduler.date.add(newPos.start_date, view.x_step, view.x_unit);
				}
			}

			return newPos;
		},

		nextUnitsSlot: function(slot, dir, extend){
			var newPos = this.clone(slot);
			newPos.section = slot.section || this._getNextSection();

			var section = slot.section || this._getNextSection();
			var state = scheduler.getState();
			var view = scheduler._props[state.mode];
			switch (dir){
				case "left":
					section = this._getNextSection(slot.section, -1);
					var optionsCount = view.size ? (view.size - 1) : view.options.length;

					if(view.days > 1 && (view.order[section] == optionsCount - 1)){
						if(scheduler.date.add(slot.start_date, -1, "day").valueOf() >= state.min_date.valueOf()){
							newPos = this.nextDaySlot(slot, dir, extend);
						}
					}
					break;
				case "right":
					section = this._getNextSection(slot.section, 1);
					if(view.days > 1 && !view.order[section]){
						if(scheduler.date.add(slot.start_date, 1, "day").valueOf() < state.max_date.valueOf()){
							newPos = this.nextDaySlot(slot, dir, extend);
						}
					}

					break;
				default:
					newPos = this.nextDaySlot(slot, dir, extend);
					section = slot.section;
					break;
			}
			newPos.section = section;
			return newPos;
		},

		_moveDate: function(oldDate, dir){
			var newDate = this.findVisibleColumn(scheduler.date.add(oldDate, dir, "day"), dir);
			newDate.setHours(oldDate.getHours());
			newDate.setMinutes(oldDate.getMinutes());
			return newDate;
		},

		isBeforeLastHour: function(date, isStartDate){
			var minutes = date.getMinutes(),
				hours = date.getHours(),
				last_hour = scheduler.config.last_hour;
			return (hours < last_hour || (!isStartDate && ((last_hour == 24 || hours == last_hour) && !minutes)));
		},
		isAfterFirstHour: function(date, isStartDate){
			var minutes = date.getMinutes(),
				hours = date.getHours(),
				first_hour = scheduler.config.first_hour,
				last_hour = scheduler.config.last_hour;
			return (hours >= first_hour || (!isStartDate && (!minutes && ((!hours &&  last_hour == 24) || (hours == last_hour)))));
		},
		isInVisibleDayTime: function(date, isStartDate){
			return (this.isBeforeLastHour(date, isStartDate) && this.isAfterFirstHour(date, isStartDate));
		},

		nextDaySlot: function(slot, dir, extend){
			var start, end;

			var key_nav_step = scheduler.config.key_nav_step;

			var date = this._alignTimeSlot(slot.start_date, scheduler.date.day_start(new Date(slot.start_date)), "minute", key_nav_step);


			var oldStart = slot.start_date;

			switch (dir){
				case "up":
					start = scheduler.date.add(date, -key_nav_step, "minute");

					if(!this.isInVisibleDayTime(start, true)){
						if (!extend || this.isInVisibleDayTime(oldStart, true)) {

							var toNextDay = true;
							if(extend && scheduler.date.date_part(new Date(start)).valueOf() != scheduler.date.date_part(new Date(oldStart)).valueOf())
								toNextDay = false;
							if(toNextDay)
								start = this.findVisibleColumn(scheduler.date.add(slot.start_date, -1, "day"), -1);

							start.setHours(scheduler.config.last_hour);
							start.setMinutes(0);
							start = scheduler.date.add(start, -key_nav_step, "minute");
						}
					}
					end = scheduler.date.add(start, key_nav_step, "minute");
					break;
				case "down":
					start = scheduler.date.add(date, key_nav_step, "minute");

					var testEnd = extend ? start : scheduler.date.add(start, key_nav_step, "minute");
					if(!this.isInVisibleDayTime(testEnd, false)){
						if(!extend || this.isInVisibleDayTime(oldStart, false)) {
							if (!extend) {
								start = this.findVisibleColumn(scheduler.date.add(slot.start_date, 1, "day"), 1);
								start.setHours(scheduler.config.first_hour);
								start.setMinutes(0);
							} else {
								var toNextDay = true;
								if (scheduler.date.date_part(new Date(oldStart)).valueOf() == oldStart.valueOf()) {
									toNextDay = false;
								}
								if (toNextDay) {
									start = this.findVisibleColumn(scheduler.date.add(slot.start_date, 1, "day"), 1);
								}
								start.setHours(scheduler.config.first_hour);
								start.setMinutes(0);
								start = scheduler.date.add(start, key_nav_step, "minute");
							}

						}
					}
					end = scheduler.date.add(start, key_nav_step, "minute");
					break;
				case "left":
					start = this._moveDate(slot.start_date, -1);
					end = this._moveDate(slot.end_date, -1);
					break;
				case "right":
					start = this._moveDate(slot.start_date, 1);
					end = this._moveDate(slot.end_date, 1);
					break;
				default:
					start = date;
					end = scheduler.date.add(start, key_nav_step, "minute");
					break;
			}

			return {start_date: start, end_date: end};
		},
		nextWeekAgendaSlot: function(slot, dir){
			var start, end;
			var state = scheduler.getState();

			switch (dir){
				case "down":
				case "left":
					start = scheduler.date.day_start(scheduler.date.add(slot.start_date, -1, "day"));
					start = this.findVisibleColumn(start, -1);
					break;
				case "up":
				case "right":
					start = scheduler.date.day_start(scheduler.date.add(slot.start_date, 1, "day"));
					start = this.findVisibleColumn(start, 1);
					break;
				default:
					start = scheduler.date.day_start(slot.start_date);
					break;
			}
			if(slot.start_date.valueOf() < state.min_date.valueOf() || slot.start_date.valueOf() >= state.max_date.valueOf()){
				start = new Date(state.min_date);

			}
			end = new Date(start);
			end.setHours(scheduler.config.last_hour);
			return {start_date: start, end_date: end};
		},
		nextAgendaSlot: function(slot, dir){
			return {start_date: slot.start_date, end_date: slot.end_date};
		},


		isDateVisible: function(date){
			if(!scheduler._ignores_detected)
				return true;

			var timeline = scheduler.matrix && scheduler.matrix[scheduler.getState().mode];

			var index;
			if(timeline){
				index = scheduler._get_date_index(timeline, date);
			}else{
				index = scheduler.locate_holder_day(date);
			}

			return !scheduler._ignores[index];
		},

		findVisibleColumn: function(start, dir){
			var date = start;
			dir = dir || 1;
			var range = scheduler.getState();

			while(!this.isDateVisible(date) && ((dir > 0 && date.valueOf() <= range.max_date.valueOf()) || (dir < 0 && date.valueOf() >= range.min_date.valueOf()))){
				date = this.nextDateColumn(date, dir);
			}

			return date;
		},

		nextDateColumn: function(start, dir){
			dir = dir || 1;
			var timeline = scheduler.matrix && scheduler.matrix[scheduler.getState().mode];

			var date;
			if(timeline){
				date = scheduler.date.add(start, dir * timeline.x_step, timeline.x_unit);
			}else{
				date = scheduler.date.day_start(scheduler.date.add(start, dir, "day"));
			}

			return date;
		},

		isVisible:function(from, to){
			if(!scheduler._ignores_detected)
				return true;

			var current = new Date(from);

			while(current.valueOf() < to.valueOf()){
				if(this.isDateVisible(current)) return true;
				current = this.nextDateColumn(current);
			}
			return false;
		},

		nextSlot: function(slot, dir, view, extend){
			var next;
			view = view || this._getMode();

			var tempSlot = scheduler.$keyboardNavigation.TimeSlot.prototype.clone(slot);

			switch (view){
				case this._modes.units:
					next = this.nextUnitsSlot(tempSlot, dir, extend);
					break;
				case this._modes.timeline:
					next = this.nextTimelineSlot(tempSlot, dir, extend);
					break;
				case this._modes.year:
					next = this.nextMonthSlot(tempSlot, dir, extend);
					break;
				case this._modes.month:
					next = this.nextMonthSlot(tempSlot, dir, extend);
					break;
				case this._modes.weekAgenda:
					next = this.nextWeekAgendaSlot(tempSlot, dir, extend);
					break;
				case this._modes.list:
					next = this.nextAgendaSlot(tempSlot, dir, extend);
					break;
				case this._modes.dayColumns:
					next = this.nextDaySlot(tempSlot, dir, extend);
					break;
			}

			if(next.start_date.valueOf() >= next.end_date.valueOf()){
				next = this.nextSlot(next, dir, view);
			}

			return scheduler.$keyboardNavigation.TimeSlot.prototype.clone(next);

		},

		extendSlot: function(slot, dir){
			var view = this._getMode();
			var next;
			switch (view){
				case this._modes.units:
					if(dir == "left" || dir == "right"){
						next = this.nextUnitsSlot(slot, dir);
					}else{
						next = this.extendUnitsSlot(slot, dir);
					}
					break;
				case this._modes.timeline:
					if(dir == "down" || dir == "up"){
						next = this.nextTimelineSlot(slot, dir);
					}else{
						next = this.extendTimelineSlot(slot, dir);
					}
					break;
				case this._modes.year:
					next = this.extendMonthSlot(slot, dir);
					break;
				case this._modes.month:
					next = this.extendMonthSlot(slot, dir);
					break;
				case this._modes.dayColumns:
					next = this.extendDaySlot(slot, dir);
					break;
				case this._modes.weekAgenda:
					next = this.extendWeekAgendaSlot(slot, dir);
					break;
				default:
					next = slot;
					break;
			}

			var range = scheduler.getState();
			if(next.start_date.valueOf() < range.min_date.valueOf()){
				next.start_date = this.findVisibleColumn(range.min_date);
				next.start_date.setHours(scheduler.config.first_hour);
			}

			if(next.end_date.valueOf() > range.max_date.valueOf()){
			//	next.end_date =  new Date(slot.end_date);
				next.end_date = this.findVisibleColumn(range.max_date, -1);
			}


			return scheduler.$keyboardNavigation.TimeSlot.prototype.clone(next);
		},

		extendTimelineSlot: function(slot, direction){
			return this.extendGenericSlot({
				"left":"start_date",
				"right":"end_date"
				},
				slot,
				direction,
				"timeline"
			);
		},

		extendWeekAgendaSlot: function(slot, direction){
			return this.extendGenericSlot({
					"left":"start_date",
					"right":"end_date"
				},
				slot,
				direction,
				"weekAgenda"
			);
		},

		extendGenericSlot: function(allowedDirections, slot, direction, type){
			var next;
			var moveDate = slot.movingDate;

			if(!moveDate){
				moveDate = allowedDirections[direction];
			}

			if(!moveDate || !allowedDirections[direction]){
				return slot;
			}

			if(direction){
				next = this.nextSlot({start_date: slot[moveDate], section: slot.section}, direction, type, true);

				if(next.start_date.valueOf() == slot.start_date.valueOf()){
					next = this.nextSlot({start_date: next.start_date, section:next.section}, direction, type, true);
				}

				next.movingDate = moveDate;
			}else{
				return scheduler.$keyboardNavigation.TimeSlot.prototype.clone(slot);
			}

			var newDates = this.extendSlotDates(slot, next, next.movingDate);

			if(newDates.end_date.valueOf() <= newDates.start_date.valueOf()){
				next.movingDate = next.movingDate == "end_date" ? "start_date" : "end_date";
			}
			newDates = this.extendSlotDates(slot, next, next.movingDate);

			next.start_date = newDates.start_date;
			next.end_date = newDates.end_date;
			return next;
		},

		extendSlotDates: function(oldSlot, newSlot, dateDirection){
			var res = {start_date:null, end_date:null};

			if(dateDirection == "start_date"){
				res.start_date = newSlot.start_date;
				res.end_date = oldSlot.end_date;
			}else{
				res.start_date = oldSlot.start_date;
				res.end_date = newSlot.start_date;
			}
			return res;

		},

		extendMonthSlot: function(slot, direction){
			var slot = this.extendGenericSlot({
					"up":"start_date",
					"down":"end_date",
					"left":"start_date",
					"right":"end_date"
				},
				slot,
				direction,
				"month"
			);

			slot.start_date.setHours(scheduler.config.first_hour);
			slot.end_date = scheduler.date.add(slot.end_date, -1, "day");
			slot.end_date.setHours(scheduler.config.last_hour);
			return slot;
		},

		extendUnitsSlot: function(slot, direction){
			var next;

			switch (direction){
				case "down":
				case "up":
					next = this.extendDaySlot(slot, direction);
					break;
				default:
					next = slot;
					break;
			}
			next.section = slot.section;
			return next;
		},
		extendDaySlot: function(slot, direction){
			return this.extendGenericSlot({
					"up":"start_date",
					"down":"end_date",
					"left":"start_date",
					"right":"end_date"
				},
				slot,
				direction,
				"dayColumns"
			);
		},

		scrollSlot: function(dir){
			var state = scheduler.getState();

			var slot = this.nextSlot(this, dir);
			if(slot.start_date.valueOf() < state.min_date.valueOf() || slot.start_date.valueOf() >= state.max_date.valueOf()){
				scheduler.setCurrentView(new Date(slot.start_date));
			}

			this.moveTo(slot);
		},

		keys: {
			"left": function(){
				this.scrollSlot("left");
			},
			"right": function () {
				this.scrollSlot("right");
			},
			"down": function () {

				var mode =  this._getMode();
				if(mode == this._modes.list){
					scheduler.$keyboardNavigation.SchedulerNode.prototype.nextEventHandler();
				}else{
					this.scrollSlot("down");
				}

			},

			"up": function () {
				var mode =  this._getMode();
				if(mode == this._modes.list){
					scheduler.$keyboardNavigation.SchedulerNode.prototype.prevEventHandler();
				}else{
					this.scrollSlot("up");
				}

			},

			"shift+down":function(){
				this.moveTo(this.extendSlot(this, "down"));
			},
			"shift+up":function(){
				this.moveTo(this.extendSlot(this, "up"));
			},
			"shift+right":function(){
				this.moveTo(this.extendSlot(this, "right"));
			},
			"shift+left":function(){
				this.moveTo(this.extendSlot(this, "left"));
			},


			"enter": function(){
				var obj = {start_date: new Date(this.start_date), end_date: new Date(this.end_date)};

				var mode = scheduler.getState().mode;
				if(scheduler.matrix && scheduler.matrix[mode]){

					var timeline = scheduler.matrix[scheduler.getState().mode];
					obj[timeline.y_property] = this.section;
				}else if(scheduler._props && scheduler._props[mode]){
					var unit = scheduler._props[mode];
					obj[unit.map_to] = this.section;
				}

				scheduler.addEventNow(obj);
			}
		}
	}
);

scheduler.$keyboardNavigation.TimeSlot.prototype.bindAll(scheduler.$keyboardNavigation.TimeSlot.prototype.keys);

}