export default function DataProcessorEvents(scheduler, dp){
	this.$scheduler = scheduler;
	this.$dp = dp;
	this._dataProcessorHandlers = [];

	this.attach = function () {
		var dp = this.$dp;
		var scheduler = this.$scheduler;

		this._dataProcessorHandlers.push(scheduler.attachEvent("onEventAdded", function(id) {
			if (!this._loading && this._validId(id))
			dp.setUpdated(id,true,"inserted");
		}));

		this._dataProcessorHandlers.push(scheduler.attachEvent("onConfirmedBeforeEventDelete", function(id) {
			if (!this._validId(id)) return;
			var z=dp.getState(id);

			if (z=="inserted" || this._new_event) { dp.setUpdated(id,false); return true; }
			if (z=="deleted") return false;
			if (z=="true_deleted") return true;

			dp.setUpdated(id,true,"deleted");
			return false;
		}));

		this._dataProcessorHandlers.push(scheduler.attachEvent("onEventChanged", function(id) { 
			if (!this._loading && this._validId(id))
			dp.setUpdated(id,true,"updated");
		}));

		this._dataProcessorHandlers.push(scheduler.attachEvent("onClearAll", function() { 
			// clear dataprocessor state when scheduler is reset
			dp._in_progress={};
			dp._invalid={};
			dp.updatedRows = [];
			dp._waitMode = 0;
		}));

		dp.attachEvent("insertCallback", scheduler._update_callback);
		dp.attachEvent("updateCallback", scheduler._update_callback);
		dp.attachEvent("deleteCallback", function(upd, id) {
			if (scheduler.getEvent(id)){
				scheduler.setUserData(id, this.action_param, "true_deleted");
				scheduler.deleteEvent(id);
			} else if (scheduler._add_rec_marker)
				scheduler._update_callback(upd, id);
		});
	};

	this.detach = function () {
		for (var key in this._dataProcessorHandlers) {
			var handler = this._dataProcessorHandlers[key];
			this.$scheduler.detachEvent(handler);
		}
		this._dataProcessorHandlers = [];
	};
}

export function extendScheduler(scheduler, dp){
	scheduler._validId=function(id){
		if(this._is_virtual_event){
			return !this._is_virtual_event(id);
		}
		return true;
	};
	scheduler.setUserData=function(id,name,value){
		if (id){
			var ev = this.getEvent(id);
			if(ev) ev[name]=value;
		}else{
			this._userdata[name]=value;
		}
	};
	scheduler.getUserData=function(id,name){
		if (id){
			var ev = this.getEvent(id);
			if(ev)
				return ev[name];
			else
				return null;
		}else{
			return this._userdata[name];
		}
	};
	scheduler._set_event_text_style=function(id,style){
		if(!scheduler.getEvent(id))
			return;
		this.for_rendered(id,function(r){
			r.style.cssText+=";"+style;
		});
		var ev = this.getEvent(id);
		ev["_text_style"]=style;
		this.event_updated(ev);
	};
	scheduler._update_callback = function(upd,id){
		var data = scheduler._xmlNodeToJSON(upd.firstChild);
		//fix for updates of recurring events
		if (data.rec_type == "none") data.rec_pattern = "none";
		data.text = data.text||data._tagvalue;
		data.start_date = scheduler._helpers.parseDate(data.start_date);
		data.end_date = scheduler._helpers.parseDate(data.end_date);
		scheduler.addEvent(data);
		if (scheduler._add_rec_marker)
			scheduler.setCurrentView();
	};
	scheduler._dp_change_event_id = function(id, new_id){
		if(!scheduler.getEvent(id))
			return;
		scheduler.changeEventId(id, new_id);
	};
	scheduler._dp_hook_delete = function(id, new_id){
		if(!scheduler.getEvent(id))
			return;

		if(new_id && id != new_id){
			if(this.getUserData(id, dp.action_param) == "true_deleted")
				this.setUserData(id, dp.action_param, "updated");

			this.changeEventId(id, new_id);
		}
		return this.deleteEvent(new_id, true);
	};

	scheduler.setDp = function() {
		this._dp = dp;
	};
	scheduler.setDp();
}