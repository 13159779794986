
export default function(){
/* global dhtmlx */
if (typeof dhtmlx != "undefined" && dhtmlx.attaches) {
	dhtmlx.attaches.attachScheduler = function (day, mode, tabs, scheduler) {
		var tabs = tabs || '<div class="dhx_cal_tab" name="day_tab" data-tab="day" style="right:204px;"></div><div class="dhx_cal_tab" name="week_tab" data-tab="week" style="right:140px;"></div><div class="dhx_cal_tab" name="month_tab" data-tab="month" style="right:76px;"></div>';
		var obj = document.createElement("DIV");
		obj.id = "dhxSchedObj_" + this._genStr(12);
		obj.innerHTML = '<div id="' + obj.id + '" class="dhx_cal_container" style="width:100%; height:100%;"><div class="dhx_cal_navline"><div class="dhx_cal_prev_button"></div><div class="dhx_cal_next_button"></div><div class="dhx_cal_today_button"></div><div class="dhx_cal_date"></div>' + tabs + '</div><div class="dhx_cal_header"></div><div class="dhx_cal_data"></div></div>';
		document.body.appendChild(obj.firstChild);

		this.attachObject(obj.id, false, true);

		this.vs[this.av].sched = scheduler;
		this.vs[this.av].schedId = obj.id;
		scheduler.setSizes = scheduler.updateView;
		scheduler.destructor = function () { };
		scheduler.init(obj.id, day, mode);

		return this.vs[this._viewRestore()].sched;
	};
}

}