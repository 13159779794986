function createMethod(scheduler){
	var methods = {};
	var isActive = false;
	function disableMethod(methodName, dummyMethod){
		dummyMethod = typeof dummyMethod == "function" ? dummyMethod : function(){};

		if(!methods[methodName]){
			methods[methodName] = this[methodName];
			this[methodName] = dummyMethod;
		}
	}
	function restoreMethod(methodName){
		if(methods[methodName]){
			this[methodName] = methods[methodName];
			methods[methodName] = null;
		}
	}
	function disableMethods(methodsHash){
		for(var i in methodsHash){
			disableMethod.call(this, i, methodsHash[i]);
		}
	}
	function restoreMethods(){
		for(var i in methods){
			restoreMethod.call(this, i);
		}
	}

	function batchUpdatePayload(callback){
		try{
			callback();
		}catch(e){
			window.console.error(e);
		}
	}

	scheduler.$stateProvider.registerProvider("batchUpdate", function(){
		return {
			batch_update: isActive
		};
	}, false);

	return function batchUpdate(callback, noRedraw) {
		if(isActive){
			// batch mode is already active
			batchUpdatePayload(callback);
			return;
		}

		var call_dp = (this._dp && this._dp.updateMode != "off");
		var dp_mode;
		if (call_dp){
			dp_mode = this._dp.updateMode;
			this._dp.setUpdateMode("off");
		}

		// temporary disable some methods while updating multiple events

		const calls = {
			setModeDate: {date: null, mode: null},
			needRender: false,
			needUpdateView: false,
			repaintEvents: {}

		};

		const rememberModeDate = (date, mode) => {
			if(date){
				calls.setModeDate.date = date;
			}
			if(mode){
				calls.setModeDate.mode = mode;
			}
		};

		var methods = {
			render:(date, mode) => {calls.needRender = true; rememberModeDate(date, mode);},
			setCurrentView:(date, mode) => {calls.needRender = true; rememberModeDate(date, mode);},
			updateView:(date, mode) => {calls.needUpdateView = true; rememberModeDate(date, mode);},
			render_data:() => calls.needRender = true,
			render_view_data:(evs) => { 
				if(evs && evs.length){
					evs.forEach((e) => calls.repaintEvents[e.id] = true);
				}else{
					calls.needRender = true;
				}
			}
		};

		disableMethods.call(this, methods);

		isActive = true;
		this.callEvent("onBeforeBatchUpdate", []);

		batchUpdatePayload(callback);

		this.callEvent("onAfterBatchUpdate", []);

		restoreMethods.call(this);

		isActive = false;
		if(!noRedraw){
			if(calls.needRender){
				scheduler.render(calls.setModeDate.date, calls.setModeDate.mode);
			}else if(calls.needUpdateView){
				scheduler.updateView(calls.setModeDate.date, calls.setModeDate.mode);
			}else{
				for(const i in calls.repaintEvents){
					scheduler.updateEvent(i);
				}
			}
		}

		if (call_dp) {
			this._dp.setUpdateMode(dp_mode);
			this._dp.sendData();
		}
	};
}

export default function(scheduler){
	scheduler.batchUpdate = createMethod(scheduler);
}