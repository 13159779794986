export default {
	date: {
		month_full: ["Januar", "Februar", "Marec", "April", "Maj", "Junij", "Julij", "Avgust", "September", "Oktober", "November", "December"],
		month_short: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
		day_full: ["Nedelja", "Ponedeljek", "Torek", "Sreda", "Četrtek", "Petek", "Sobota"],
		day_short: ["Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"]
	},
	labels: {
		dhx_cal_today_button: "Danes",
		day_tab: "Dan",
		week_tab: "Teden",
		month_tab: "Mesec",
		new_event: "Nov dogodek",
		icon_save: "Shrani",
		icon_cancel: "Prekliči",
		icon_details: "Podrobnosti",
		icon_edit: "Uredi",
		icon_delete: "Izbriši",
		confirm_closing: "", //Spremembe ne bodo shranjene. Želite nadaljevati ?
		confirm_deleting: "Dogodek bo izbrisan. Želite nadaljevati?",
		section_description: "Opis",
		section_time: "Časovni okvir",
		full_day: "Ves dan",

		/*recurring events*/
		confirm_recurring: "Želite urediti celoten set ponavljajočih dogodkov?",
		section_recurring: "Ponovi dogodek",
		button_recurring: "Onemogočeno",
		button_recurring_open: "Omogočeno",
		button_edit_series: "Edit series",
		button_edit_occurrence: "Edit occurrence",

		/*agenda view extension*/
		agenda_tab: "Zadeva",
		date: "Datum",
		description: "Opis",

		/*year view extension*/
		year_tab: "Leto",

		/*week agenda view extension*/
		week_agenda_tab: "Zadeva",

		/*grid view extension*/
		grid_tab: "Miza",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Dnevno", //name="repeat" value="day"
		repeat_radio_week: "Tedensko", //name="repeat" value="week
		repeat_radio_month: "Mesečno",
		repeat_radio_year: "Letno",
		repeat_radio_day_type: "Vsak",
		repeat_text_day_count: "dan",
		repeat_radio_day_type2: "Vsak delovni dan",
		repeat_week: " Ponavljaj vsak",
		repeat_text_week_count: "teden na naslednje dni:",
		repeat_radio_month_type: "Ponavljaj",
		repeat_radio_month_start: "Na",
		repeat_text_month_day: "dan vsak",
		repeat_text_month_count: "mesec",
		repeat_text_month_count2_before: "vsak",
		repeat_text_month_count2_after: "mesec",
		repeat_year_label: "Na",
		select_year_day2: "od",
		repeat_text_year_day: "dan",
		select_year_month: "mesec",
		repeat_radio_end: "Brez končnega datuma",
		repeat_text_occurences_count: "pojavitve",
		repeat_radio_end2: "Po",
		repeat_radio_end3: "Končaj do",

		repeat_never: "Nikoli",
		repeat_daily: "Vsak dan",
		repeat_workdays: "Vsak delovni dan",
		repeat_weekly: "Vsak teden",
		repeat_monthly: "Vsak mesec",
		repeat_yearly: "Vsako leto",
		repeat_custom: "Po meri",
		repeat_freq_day: "Dan",
		repeat_freq_week: "Teden",
		repeat_freq_month: "Mesec",
		repeat_freq_year: "Leto",
		repeat_on_date: "Na datum",
		repeat_ends: "Konča se",

		month_for_recurring: ["Januar", "Februar", "Marec", "April", "Maj", "Junij", "Julij", "Avgust", "September", "Oktober", "November", "December"],
		day_for_recurring: ["Nedelja", "Ponedeljek", "Torek", "Sreda", "Četrtek", "Petek", "Sobota"]
	}
};

