export default function extend(scheduler) {

var commonViews = {
	agenda: "https://docs.dhtmlx.com/scheduler/agenda_view.html",
	grid: "https://docs.dhtmlx.com/scheduler/grid_view.html",
	map: "https://docs.dhtmlx.com/scheduler/map_view.html",
	unit: "https://docs.dhtmlx.com/scheduler/units_view.html",
	timeline: "https://docs.dhtmlx.com/scheduler/timeline_view.html",
	week_agenda: "https://docs.dhtmlx.com/scheduler/weekagenda_view.html",
	year: "https://docs.dhtmlx.com/scheduler/year_view.html",
	anythingElse: "https://docs.dhtmlx.com/scheduler/views.html"
};

var requiredExtensions = {
	agenda: "ext/dhtmlxscheduler_agenda_view.js",
	grid: "ext/dhtmlxscheduler_grid_view.js",
	map: "ext/dhtmlxscheduler_map_view.js",
	unit: "ext/dhtmlxscheduler_units.js",
	timeline: "ext/dhtmlxscheduler_timeline.js, ext/dhtmlxscheduler_treetimeline.js, ext/dhtmlxscheduler_daytimeline.js",
	week_agenda: "ext/dhtmlxscheduler_week_agenda.js",
	year: "ext/dhtmlxscheduler_year_view.js",
	limit: "ext/dhtmlxscheduler_limit.js"
};

scheduler._commonErrorMessages = {
	unknownView: function(view){
		var relatedDoc = "Related docs: " + (commonViews[view] || commonViews.anythingElse); 
		var relatedExtension = requiredExtensions[view] ? ("You're probably missing " + requiredExtensions[view] + ".") : "";
		return (
			"`"+view+"` view is not defined. \n" +
			"Please check parameters you pass to `scheduler.init` or `scheduler.setCurrentView` in your code and ensure you've imported appropriate extensions. \n" + 
			relatedDoc + "\n" + (relatedExtension ? (relatedExtension + "\n") : ""));
	},
	collapsedContainer: function(div){
		return "Scheduler container height is set to *100%* but the rendered height is zero and the scheduler is not visible. \n"+
		"Make sure that the container has some initial height or use different units. For example:\n" +
		"<div id='scheduler_here' class='dhx_cal_container' style='width:100%; height:600px;'> \n";
	}
};

scheduler.createTimelineView = function() {
	throw new Error("scheduler.createTimelineView is not implemented. Be sure to add the required extension: " + requiredExtensions.timeline + 
	"\n" +
	"Related docs: " + commonViews.timeline);
};

scheduler.createUnitsView = function() {
	throw new Error("scheduler.createUnitsView is not implemented. Be sure to add the required extension: " + requiredExtensions.unit + 
	"\n" +
	"Related docs: " + commonViews.unit);
};

scheduler.createGridView = function() {
	throw new Error("scheduler.createGridView is not implemented. Be sure to add the required extension: " + requiredExtensions.grid + 
	"\n" +
	"Related docs: " + commonViews.grid);
};

scheduler.addMarkedTimespan = function() {
	throw new Error("scheduler.addMarkedTimespan is not implemented. Be sure to add the required extension: ext/dhtmlxscheduler_limit.js" + 
	"\n" +
	"Related docs: https://docs.dhtmlx.com/scheduler/limits.html");
};

scheduler.renderCalendar = function() {
	throw new Error("scheduler.renderCalendar is not implemented. Be sure to add the required extension: ext/dhtmlxscheduler_minical.js" + 
	"\n" +
	"https://docs.dhtmlx.com/scheduler/minicalendar.html");
};

scheduler.exportToPNG = function() {
	throw new Error([
		"scheduler.exportToPNG is not implemented.",
		"This feature requires an additional module, be sure to check the related doc here https://docs.dhtmlx.com/scheduler/png.html",
		"Licensing info: https://dhtmlx.com/docs/products/dhtmlxScheduler/export.shtml"
	].join("\n"));
};

scheduler.exportToPDF = function() {
	throw new Error([
		"scheduler.exportToPDF is not implemented.",
		"This feature requires an additional module, be sure to check the related doc here https://docs.dhtmlx.com/scheduler/pdf.html",
		"Licensing info: https://dhtmlx.com/docs/products/dhtmlxScheduler/export.shtml"
	].join("\n"));
};


}