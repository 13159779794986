import { IMapAdapter, ICalendarEvent, IMapSettings, IMapPosition} from "./types";
declare let google: any;

export class GoogleMapAdapter implements IMapAdapter {
	private map: any;
	private scheduler: any;
	private settings: IMapSettings;
	private _markers: any[];
	private infoWindow: any;
	constructor(scheduler: any) {
		this.map = null;
		this._markers = [];
		this.scheduler = scheduler;
	}
	onEventClick(event: ICalendarEvent): void {
		if (this._markers && this._markers.length > 0) {
			for (let i = 0; i < this._markers.length; i++) {
				if (event.id == this._markers[i].event.id) {
					let zoom = this.settings.zoom_after_resolve || this.settings.initial_zoom;
					if (event.lat && event.lng){
						this.map.setCenter({
							lat: event.lat, 
							lng: event.lng,
						});
						this.map.setZoom(zoom);
					} else {						
						this.map.setCenter({
							lat: this.settings.error_position.lat, 
							lng: this.settings.error_position.lng,
						});
						this.map.setZoom(zoom);
					}
					google.maps.event.trigger(this._markers[i].marker, 'click');
				}	
			}
		} 
	}

	initialize(container: HTMLElement, options: IMapSettings): void {
		this.settings = options;
		let scheduler = this.scheduler;
		let mapOptions = {
			center: { lat: options.initial_position.lat, lng:  options.initial_position.lng },
			zoom: options.initial_zoom,
			mapId: container.id,
			scrollwheel: true,
			mapTypeId: options.type
		}
		// we need to reuse the google map to avoid leak memory
		if (this.map === null) {
			this.map = new google.maps.Map(container, mapOptions);
		}
		else {
			let map = this.map;
			container.appendChild(this.map.__gm.messageOverlay);
			container.appendChild(this.map.__gm.outerContainer);
			setTimeout(function() {
				map.setOptions({container: container.id});
			}, 500);
		}
		google.maps.event.addListener(this.map, "dblclick", function(event) {
			const geocoder = new google.maps.Geocoder();
	
			if (!scheduler.config.readonly && scheduler.config.dblclick_create) {
				let point = event.latLng;
				geocoder.geocode(
					{ 'latLng': point },
					function(results, status) {
						if (status == google.maps.GeocoderStatus.OK) {
							point = results[0].geometry.location;
							scheduler.addEventNow({
								lat: point.lat(),
								lng: point.lng(),
								event_location: results[0].formatted_address,
								start_date: scheduler.getState().date,
								end_date: scheduler.date.add(scheduler.getState().date, scheduler.config.time_step, "minute")
							});
						} else {
							console.error('Geocode was not successful for the following reason: ' + status);
						}
					}
				);
			}
		});
	}

	destroy(container: HTMLElement): void {
		google.maps.event.clearInstanceListeners(window);
		google.maps.event.clearInstanceListeners(document);
		google.maps.event.clearInstanceListeners(container);
		while (container.firstChild) {
			container.firstChild.remove();
		}
		container.innerHTML = "";
	}

	async addEventMarker(event: ICalendarEvent): Promise<void> {
		let config = {
			title: event.text,
			position: {},
			map: {}
		}
		
		if (event.lat && event.lng) {
			config.position = { lat: event.lat, lng: event.lng };
		} else {
			config.position = { lat: this.settings.error_position.lat, lng: this.settings.error_position.lng }
		}
		const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
		let marker;
		if (this.scheduler.ext.mapView.createMarker) {
			config.map = this.map;
			marker = this.scheduler.ext.mapView.createMarker(config);
		} else {
			marker = new AdvancedMarkerElement(config);
			marker.map = this.map;
		}
		marker.setMap(this.map);
		//case for markeks which were deleted with DataProcessor
		if (event["!nativeeditor_status"] == "true_deleted"){
			marker.setMap(null);
		}
		google.maps.event.addListener(marker, "click", () => {
			if (this.infoWindow) {
				this.infoWindow.close();
			}
			this.infoWindow = new google.maps.InfoWindow({maxWidth: this.settings.info_window_max_width});
			this.infoWindow.setContent(this.scheduler.templates.map_info_content(event));
			this.infoWindow.open({
				anchor: marker,
				map: this.map,
			});
		});
		let markerInfo = {event, ...config, marker};
		this._markers.push(markerInfo);
	}

	removeEventMarker(eventId: string): void {
		for (let i = 0; i < this._markers.length; i++) {
			if (eventId == this._markers[i].event.id) {
				this._markers[i].marker.setVisible(false);
				this._markers[i].marker.setMap(null);
				this._markers[i].marker.setPosition(null);
				this._markers[i].marker = null;
				this._markers.splice(i,1);
				i--;
			}
		}
	}

	updateEventMarker(event: ICalendarEvent): void {
		for (let i = 0; i < this._markers.length; i++) {
			if(this._markers[i].event.id == event.id) {
				this._markers[i].event = event;
				this._markers[i].position.lat = event.lat;
				this._markers[i].position.lng = event.lng;
				this._markers[i].text = event.text;
				let latlng = new google.maps.LatLng(event.lat, event.lng);
				this._markers[i].marker.setPosition(latlng);
			}
		}
	}

	clearEventMarkers(): void {
		if (this._markers.length > 0) {
			for (let i = 0; i < this._markers.length; i++) {
				this._markers[i].marker.setMap(null);
			}
			this._markers = [];
		}
	}

	setView(latitude: number, longitude: number, zoom: number): void {
		this.map.setCenter({
			lat: latitude, 
			lng: longitude,
		});
		this.map.setZoom(zoom);
	}

	async resolveAddress(string: string): Promise<IMapPosition> {
		const geocoder = new google.maps.Geocoder();
		let position: any = await new Promise((resolve) => {
			geocoder.geocode(
				{ 'address': string },
				function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						resolve({
						 lat: results[0].geometry.location.lat(),
						 lng: results[0].geometry.location.lng()
						});
					} else {
						console.error('Geocode was not successful for the following reason: ' + status);
						resolve({});
					}
				}
			);
		});
		return position;
	}
	
}