export default class ExtensionsManager{
	constructor(config){
		this._extensions = {};
		for(const i in config){
			this._extensions[i] = config[i];
		}
	}

	addExtension(name, ext) {
		this._extensions[name] = ext;
	}

	getExtension(name) {
		return this._extensions[name];
	}
}