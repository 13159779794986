export default {
	date: {
		month_full: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
		month_short: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
		day_full: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
		day_short: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"]
	},
	labels: {
		dhx_cal_today_button: "Idag",
		day_tab: "Dag",
		week_tab: "Uke",
		month_tab: "Måned",
		new_event: "Ny",
		icon_save: "Lagre",
		icon_cancel: "Avbryt",
		icon_details: "Detaljer",
		icon_edit: "Endre",
		icon_delete: "Slett",
		confirm_closing: "Endringer blir ikke lagret, er du sikker?", //Endringer blir ikke lagret, er du sikker?
		confirm_deleting: "Oppføringen vil bli slettet, er du sikker?",
		section_description: "Beskrivelse",
		section_time: "Tidsperiode",
		full_day: "Full dag",

		/*recurring events*/
		confirm_recurring: "Vil du endre hele settet med repeterende oppføringer?",
		section_recurring: "Repeterende oppføring",
		button_recurring: "Ikke aktiv",
		button_recurring_open: "Aktiv",
		button_edit_series: "Rediger serien",
		button_edit_occurrence: "Redigere en kopi",

		/*agenda view extension*/
		agenda_tab: "Agenda",
		date: "Dato",
		description: "Beskrivelse",

		/*year view extension*/
		year_tab: "År",

		/*week agenda view extension*/
		week_agenda_tab: "Agenda",

		/*grid view extension*/
		grid_tab: "Grid",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Daglig", //name="repeat" value="day"
		repeat_radio_week: "Ukentlig", //name="repeat" value="week
		repeat_radio_month: "Månedlig",
		repeat_radio_year: "Årlig",
		repeat_radio_day_type: "Hver",
		repeat_text_day_count: "dag",
		repeat_radio_day_type2: "Hver arbeidsdag",
		repeat_week: " Gjenta hver",
		repeat_text_week_count: "uke neste dager:",
		repeat_radio_month_type: "Gjenta",
		repeat_radio_month_start: "På",
		repeat_text_month_day: "dag hver",
		repeat_text_month_count: "måned",
		repeat_text_month_count2_before: "hver",
		repeat_text_month_count2_after: "måned",
		repeat_year_label: "På",
		select_year_day2: "av",
		repeat_text_year_day: "dag",
		select_year_month: "måned",
		repeat_radio_end: "Ingen sluttdato",
		repeat_text_occurences_count: "forekomster",
		repeat_radio_end2: "Etter",
		repeat_radio_end3: "Slutt innen",

		repeat_never: "Aldri",
		repeat_daily: "Hver dag",
		repeat_workdays: "Hver ukedag",
		repeat_weekly: "Hver uke",
		repeat_monthly: "Hver måned",
		repeat_yearly: "Hvert år",
		repeat_custom: "Tilpasset",
		repeat_freq_day: "Dag",
		repeat_freq_week: "Uke",
		repeat_freq_month: "Måned",
		repeat_freq_year: "År",
		repeat_on_date: "På dato",
		repeat_ends: "Slutter",

		month_for_recurring: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
		day_for_recurring: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
	}
};

