import { IMapAdapter, ICalendarEvent, IMapSettings, IMapPosition} from "./types";

declare let L: any

export class OpenStreetMapAdapter implements IMapAdapter {
	private map: any;
	private scheduler: any;
	private _markers: any[];
	private settings: IMapSettings;
	constructor(scheduler: any) {
		this.map = null;
		this._markers = [];
		this.scheduler = scheduler;
	}
	onEventClick(event: ICalendarEvent): void {
		if (this._markers && this._markers.length > 0) {
			for (let i = 0; i <  this._markers.length; i++) {
				if (event.id ==  this._markers[i].event.id) {
					this._markers[i].marker.openPopup();
					this._markers[i].marker.closeTooltip();
					if (event.lat && event.lng) {
						this.setView(event.lat, event.lng, this.settings.zoom_after_resolve || this.settings.initial_zoom);
					} else {
						this.setView(this.settings.error_position.lat, this.settings.error_position.lng, this.settings.zoom_after_resolve || this.settings.initial_zoom);
					}
				}	
			}
		} 
	}

	initialize(container: HTMLElement, options: IMapSettings): void {
		let scheduler = this.scheduler;
		let mapWrapper = document.createElement('div');
		mapWrapper.className = 'mapWrapper';
		mapWrapper.id = 'mapWrapper';
		mapWrapper.style.width = container.style.width;
		mapWrapper.style.height = container.style.height;
		container.appendChild(mapWrapper);

		let map = L.map(mapWrapper, {
			center: L.latLng(options.initial_position.lat, options.initial_position.lng),
			zoom: options.initial_zoom,
			keyboard: false // need to set false, due to bug with moving window
		});
		L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
		map.on("dblclick", async function(e){
			const settings = {
				"method": "GET",
				headers: {
					"Accept-Language":  "en",
				},
			};
			let response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${e.latlng.lat}&lon=${e.latlng.lng}&format=json`, settings).then(response => response.json());
			if (response.address) {
				let address = response.address.country;
				scheduler.addEventNow({
					lat: e.latlng.lat,
					lng: e.latlng.lng,
					event_location: address,
					start_date: scheduler.getState().date,
					end_date: scheduler.date.add(scheduler.getState().date, scheduler.config.time_step, "minute")
				});
			} else {
				console.error("unable recieve a position of the event", response.error);
			}
		});
		this.map = map;
		this.settings = options;
	}

	destroy(container: HTMLElement): void {
		this.map.remove();
		while (container.firstChild) {
			container.firstChild.remove();
		}
		container.innerHTML = "";
	}

	addEventMarker(event: ICalendarEvent): void {
		const myIcon = L.icon({
			iconUrl: 'https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png',
			iconSize:     [25, 41],
			shadowSize:   [30, 65],
			iconAnchor:   [12, 41],
			shadowAnchor: [7, 65]
		});
		let popupConfig = {
			minWidth: 180,
			maxWidth: this.settings.info_window_max_width,
		}
		const markerPopup = L.popup(popupConfig).setContent(this.scheduler.templates.map_info_content(event));
		const tooltip = L.tooltip().setContent(event.text);

		let markerConfig = [
			event.lat,
			event.lng
		]
		
		if (!event.lat || !event.lng) {
			markerConfig = [this.settings.error_position.lat, this.settings.error_position.lng];
		}
		const marker = L.marker(markerConfig, {
			icon: myIcon
		}).bindPopup(markerPopup).bindTooltip(tooltip).addTo(this.map);
		const markerInfo = {event, marker};
		this._markers.push(markerInfo);		
	}

	removeEventMarker(eventId: string): void {
		for (let i = 0; i < this._markers.length; i++) {
			if (eventId == this._markers[i].event.id) {
				this.map.removeLayer(this._markers[i].marker);
				this._markers.splice(i,1);
				i--;
			}
		}
	}

	updateEventMarker(event: ICalendarEvent): void {
		for (let i = 0; i < this._markers.length; i++) {
			if(this._markers[i].event.id == event.id) {
				this._markers[i].event = event;
				if (!event.lat || !event.lng){
					this._markers[i].marker.setLatLng([this.settings.error_position.lat, this.settings.error_position.lng]);
				} else {
					this._markers[i].marker.setLatLng([event.lat, event.lng]);
				}
			}
		}
	}

	clearEventMarkers(): void {
		if(this._markers) {
			for (let i = 0; i < this._markers.length; i++) {
				this.map.removeLayer(this._markers[i].marker); 
			}
			this._markers = [];
		}
	}

	setView(latitude: number, longitude: number, zoom: number): void {
		this.map.setView([latitude, longitude], zoom);
	}

	async resolveAddress(string: string): Promise<IMapPosition> {
		const settings = {
			"method": "GET",
			headers: {
				"Accept-Language":  "en",
			},
		};
		let position:any = {};
		let response = await fetch(`https://nominatim.openstreetmap.org/search?q=${string}&format=json`, settings).then(response => response.json());
		if (response && response.length) {
			position.lat = +response[0].lat;
			position.lng = +response[0].lon;
		} else {
			console.error(`Unable recieve a position of the event's location: ${string}`);
		}
		return position;
	}
}