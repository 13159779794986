export default function(scheduler){



(function(){
	var modalsStack = [];

	function isModal(){
		return !!(modalsStack.length);
	}

	function isChildOf(child, parent){
		while(child && child != parent){
			child = child.parentNode;
		}

		return !!(child == parent);
	}

	function afterPopup(box){
		setTimeout(function(){
			if(scheduler.$destroyed){
				return true;
			}
			if(!isModal() && !(isChildOf(document.activeElement, scheduler.$container))) {
				scheduler.focus();
			}
		}, 1);
	}
	function startModal(box){
		scheduler.eventRemove(box, "keydown", trapFocus);
		scheduler.event(box, "keydown", trapFocus);
		modalsStack.push(box);
		//scheduler.$keyboardNavigation.dispatcher.disable();
	}

	function endModal(){
		var box = modalsStack.pop();
		if(box) {
			scheduler.eventRemove(box, "keydown", trapFocus);
		}
		afterPopup(box);

	}

	function isTopModal(box){
	//	if(dhtmlx._modalsStack.length){
	//		return box == dhtmlx._modalsStack[dhtmlx._modalsStack.length - 1];
	//	}else{
			return box == modalsStack[modalsStack.length - 1];
	//	}

	}

	function trapFocus(event){
		var event = event || window.event;
		var target = event.currentTarget;
		if(!isTopModal(target)) return;

		scheduler.$keyboardNavigation.trapFocus(target, event);
	}

	function traceLightbox(){
		startModal(scheduler.getLightbox());
	}

	scheduler.attachEvent("onLightbox", traceLightbox);
	scheduler.attachEvent("onAfterLightbox", endModal);

	scheduler.attachEvent("onAfterQuickInfo", function(){afterPopup();});

	if(!scheduler._keyNavMessagePopup) {
		scheduler._keyNavMessagePopup = true;

		var focusElement = null;
		var backupFocus = null;
		const modalsStack = [];
		scheduler.attachEvent("onMessagePopup", function(box){
			focusElement = document.activeElement;
			backupFocus = focusElement;

			while(backupFocus && scheduler._getClassName(backupFocus).indexOf("dhx_cal_data") < 0){
				backupFocus = backupFocus.parentNode;
			}
			if(backupFocus){
				backupFocus = backupFocus.parentNode;
			}

			scheduler.eventRemove(box, "keydown", trapFocus);
			scheduler.event(box, "keydown", trapFocus);
			modalsStack.push(box);
		});


		scheduler.attachEvent("onAfterMessagePopup", function () {
			var box = modalsStack.pop();
			if(box) {
				scheduler.eventRemove(box, "keydown", trapFocus);
			}
			setTimeout(function(){
				if(scheduler.$destroyed){
					return true;
				}
				var currentTarget = document.activeElement;
				while(currentTarget && scheduler._getClassName(currentTarget).indexOf("dhx_cal_light") < 0){
					currentTarget = currentTarget.parentNode;
				}
				if(currentTarget)
					return;


				if(focusElement && focusElement.parentNode){
					focusElement.focus();

				}else if(backupFocus && backupFocus.parentNode){
					backupFocus.focus();
				}
				focusElement = null;
				backupFocus = null;

			}, 1);
		});
	}

	scheduler.$keyboardNavigation.isModal = isModal;


})();

}