export default {
	date: {
		month_full: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
		month_short: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Ags", "Sep", "Okt", "Nov", "Des"],
		day_full: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
		day_short: ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"]
	},
	labels: {
		dhx_cal_today_button: "Hari Ini",
		day_tab: "Hari",
		week_tab: "Minggu",
		month_tab: "Bulan",
		new_event: "Acara Baru",
		icon_save: "Simpan",
		icon_cancel: "Batal",
		icon_details: "Detail",
		icon_edit: "Edit",
		icon_delete: "Hapus",
		confirm_closing: "", /*Perubahan tidak akan disimpan ?*/
		confirm_deleting: "Acara akan dihapus",
		section_description: "Keterangan",
		section_time: "Periode",
		full_day: "Hari penuh",

		/*recurring events*/
		confirm_recurring: "Apakah acara ini akan berulang?",
		section_recurring: "Acara Rutin",
		button_recurring: "Tidak Difungsikan",
		button_recurring_open: "Difungsikan",
		button_edit_series: "Mengedit seri",
		button_edit_occurrence: "Mengedit salinan",

		/*agenda view extension*/
		agenda_tab: "Agenda",
		date: "Tanggal",
		description: "Keterangan",

		/*year view extension*/
		year_tab: "Tahun",

		/*week agenda view extension*/
		week_agenda_tab: "Agenda",

		/*grid view extension*/
		grid_tab: "Tabel",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Harian", //name="repeat" value="day"
		repeat_radio_week: "Mingguan", //name="repeat" value="week
		repeat_radio_month: "Bulanan",
		repeat_radio_year: "Tahunan",
		repeat_radio_day_type: "Setiap",
		repeat_text_day_count: "hari",
		repeat_radio_day_type2: "Setiap hari kerja",
		repeat_week: " Ulangi setiap",
		repeat_text_week_count: "minggu pada hari berikut:",
		repeat_radio_month_type: "Ulangi",
		repeat_radio_month_start: "Pada",
		repeat_text_month_day: "hari setiap",
		repeat_text_month_count: "bulan",
		repeat_text_month_count2_before: "setiap",
		repeat_text_month_count2_after: "bulan",
		repeat_year_label: "Pada",
		select_year_day2: "dari",
		repeat_text_year_day: "hari",
		select_year_month: "bulan",
		repeat_radio_end: "Tanpa tanggal akhir",
		repeat_text_occurences_count: "kejadian",
		repeat_radio_end2: "Setelah",
		repeat_radio_end3: "Berakhir pada",

		repeat_never: "Tidak pernah",
		repeat_daily: "Setiap hari",
		repeat_workdays: "Setiap hari kerja",
		repeat_weekly: "Setiap minggu",
		repeat_monthly: "Setiap bulan",
		repeat_yearly: "Setiap tahun",
		repeat_custom: "Kustom",
		repeat_freq_day: "Hari",
		repeat_freq_week: "Minggu",
		repeat_freq_month: "Bulan",
		repeat_freq_year: "Tahun",
		repeat_on_date: "Pada tanggal",
		repeat_ends: "Berakhir",

		month_for_recurring: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
		day_for_recurring: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
	}
};