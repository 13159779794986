export default function(scheduler){
	return (function(){
		var res = {};
		for (var a in this._events){
			var ev = this._events[a];
			if (ev.id.toString().indexOf("#") == -1){
				res[ev.id] = ev;
			}
		}
		return res;
	}).bind(scheduler);
}