export default {
	date: {
		month_full: ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"],
		month_short: ["Jan", "Feb", "Már", "Ápr", "Máj", "Jún", "Júl", "Aug", "Sep", "Okt", "Nov", "Dec"],
		day_full: ["Vasárnap", "Hétfõ", "Kedd", "Szerda", "Csütörtök", "Péntek", "szombat"],
		day_short: ["Va", "Hé", "Ke", "Sze", "Csü", "Pé", "Szo"]
	},
	labels: {
		dhx_cal_today_button: "Ma",
		day_tab: "Nap",
		week_tab: "Hét",
		month_tab: "Hónap",
		new_event: "Új esemény",
		icon_save: "Mentés",
		icon_cancel: "Mégse",
		icon_details: "Részletek",
		icon_edit: "Szerkesztés",
		icon_delete: "Törlés",
		confirm_closing: "", //A változások elvesznek, biztosan folytatja? "
		confirm_deleting: "Az esemény törölve lesz, biztosan folytatja?",
		section_description: "Leírás",
		section_time: "Idõszak",
		full_day: "Egesz napos",

		/*ismétlõdõ események*/
		confirm_recurring: "Biztosan szerkeszteni akarod az összes ismétlõdõ esemény beállítását?",
		section_recurring: "Esemény ismétlése",
		button_recurring: "Tiltás",
		button_recurring_open: "Engedélyezés",
		button_edit_series: "Edit series",
		button_edit_occurrence: "Szerkesztés bíróság",

		/*napirendi nézet*/
		agenda_tab: "Napirend",
		date: "Dátum",
		description: "Leírás",

		/*éves nézet*/
		year_tab: "Év",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Napi", //name="repeat" value="day"
		repeat_radio_week: "Heti", //name="repeat" value="week
		repeat_radio_month: "Havi",
		repeat_radio_year: "Éves",
		repeat_radio_day_type: "Minden",
		repeat_text_day_count: "nap",
		repeat_radio_day_type2: "Minden munkanap",
		repeat_week: " Ismételje meg minden",
		repeat_text_week_count: "héten a következő napokon:",
		repeat_radio_month_type: "Ismétlés",
		repeat_radio_month_start: "Ekkor",
		repeat_text_month_day: "nap minden",
		repeat_text_month_count: "hónapban",
		repeat_text_month_count2_before: "minden",
		repeat_text_month_count2_after: "hónapban",
		repeat_year_label: "Ekkor",
		select_year_day2: "-án/-én",
		repeat_text_year_day: "nap",
		select_year_month: "hónap",
		repeat_radio_end: "Nincs befejezési dátum",
		repeat_text_occurences_count: "esemény",
		repeat_radio_end2: "Után",
		repeat_radio_end3: "Befejező dátum",

		repeat_never: "Soha",
		repeat_daily: "Minden nap",
		repeat_workdays: "Minden munkanap",
		repeat_weekly: "Minden héten",
		repeat_monthly: "Minden hónapban",
		repeat_yearly: "Minden évben",
		repeat_custom: "Egyedi",
		repeat_freq_day: "Nap",
		repeat_freq_week: "Hét",
		repeat_freq_month: "Hónap",
		repeat_freq_year: "Év",
		repeat_on_date: "Dátum szerint",
		repeat_ends: "Befejeződik",

		month_for_recurring: ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"],
		day_for_recurring: ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"]
	}
};

