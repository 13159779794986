export default function undoDelete(scheduler) {
	scheduler.attachEvent("onEventDeleted", function (id, ev) {
		let deletedEvent = scheduler.copy(ev);
		if (scheduler.config.undo_deleted && !scheduler.getState().new_event) {
			scheduler.message({
				text: `<div class="dhx_info_message">
                            <span class="undo_popup_text">Event deleted</span>
                            <button class="undo_button" data-deleted-event-id="${ev.id}">Undo</button>
                        </div>`,
				expire: 10000,
				type: "popup_after_delete",
				callback: function (e) {
					let undoBtn = e.target.closest(
						`[data-deleted-event-id="${ev.id}"]`
					);
					if (undoBtn) {
						scheduler.addEvent(deletedEvent);
						scheduler.render();
					}
				}
			});
		}
	});
}
