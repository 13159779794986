export default function(scheduler){

scheduler.$keyboardNavigation.DataArea = function(index){
	this.index = index || 0;
};

scheduler.$keyboardNavigation.DataArea.prototype = scheduler._compose(
	scheduler.$keyboardNavigation.KeyNavNode,
	{
		getNode: function(index){
			return scheduler.$container.querySelector(".dhx_cal_data");
		},

		_handlers:null,

		isValid: function(){
			return true;
		},
		fallback:function(){
			return this;
		},

		keys: {
			"up,down,right,left":function(){
				this.moveTo(new scheduler.$keyboardNavigation.TimeSlot());
			}
		}
	}
);

scheduler.$keyboardNavigation.DataArea.prototype.bindAll(scheduler.$keyboardNavigation.DataArea.prototype.keys);

}