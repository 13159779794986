export default function extend(scheduler) {


scheduler._append_drag_marker = function(m){
	if(m.parentNode) return;
	var zone = scheduler._els["dhx_cal_data"][0];

	var scale = zone.lastChild;
	var className = scheduler._getClassName(scale);
	if(className.indexOf("dhx_scale_holder") < 0 && scale.previousSibling){
		scale = scale.previousSibling;
	}

	className = scheduler._getClassName(scale);
	if (scale && className.indexOf("dhx_scale_holder") === 0) {
		scale.appendChild(m);
	}
};

scheduler._update_marker_position = function(m, event){
	var size = scheduler._calc_event_y(event, 0);
	m.style.top = size.top + "px";
	m.style.height = size.height + "px";
};

scheduler.highlightEventPosition = function(event){
	var m = document.createElement("div");

	m.setAttribute("event_id", event.id); // for backward compatibility
	m.setAttribute(this.config.event_attribute, event.id);
	this._rendered.push(m);
	this._update_marker_position(m, event);

	var css = this.templates.drag_marker_class(event.start_date, event.end_date, event);
	var html = this.templates.drag_marker_content(event.start_date, event.end_date, event);
	m.className = "dhx_drag_marker";
	if(css)
		m.className += " " + css;
	if(html)
		m.innerHTML = html;
	this._append_drag_marker(m);
};

}