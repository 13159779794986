import defaultDomEvents from './event';

export default function(){

	var domEvents = function (addEvent, removeEvent) {
		addEvent = addEvent || defaultDomEvents.event;
		removeEvent = removeEvent || defaultDomEvents.eventRemove;
		var handlers = [];
		var eventScope = {
			attach: function(el, event, callback, capture){
				handlers.push({element: el, event:event, callback: callback, capture: capture});
				addEvent(el, event, callback, capture);
			},
			detach: function(el, event, callback, capture){
				removeEvent(el, event, callback, capture);
				for(var i = 0; i < handlers.length; i++){
					var handler = handlers[i];
					if (handler.element === el && handler.event === event && handler.callback === callback && handler.capture === capture) {
						handlers.splice(i, 1);
						i--;
					}
				}
			},
			detachAll: function () {
				var staticArray = handlers.slice();
				// original handlers array can be spliced on every iteration
				for (var i = 0; i < staticArray.length; i++){
					var handler = staticArray[i];
					eventScope.detach(handler.element, handler.event, handler.callback, handler.capture);
					eventScope.detach(handler.element, handler.event, handler.callback, undefined);
					eventScope.detach(handler.element, handler.event, handler.callback, false);
					eventScope.detach(handler.element, handler.event, handler.callback, true);
				}
				handlers.splice(0, handlers.length);
			},
			extend: function(){
				return domEvents(this.event, this.eventRemove);
			}
		};
		return eventScope;
	};
	
	return domEvents();
}