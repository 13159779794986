export default function extend(scheduler) {

scheduler.destructor = function(){
	scheduler.callEvent("onDestroy", []);
	this.clearAll();

	if(this.$container){
		// FIXME: clear container or remove container
		this.$container.innerHTML = "";
		// scheduler.$domHelpers.remove(this.$container);
	}

	if(this._eventRemoveAll){
		this._eventRemoveAll();
	}

	if(this.resetLightbox){
		this.resetLightbox();
	}

	if(this._dp && this._dp.destructor){
		this._dp.destructor();
	}

	// detachAllEvents should be called last, because in components may be attached events
	this.detachAllEvents();

	for(var i in this){
		if(i.indexOf("$") === 0){
			delete this[i];
		}
	}
	scheduler.$destroyed = true;
};

}