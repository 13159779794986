import global from "../global";

export default function extend(scheduler) {

(function(){

	var setCurrentView = scheduler.setCurrentView,
		updateView = scheduler.updateView;
	var update_view_timer = null,
		curr_view_timer = null;

	var lazy_setCurrentView = function(date, mode){
		var self = this;
		global.clearTimeout(curr_view_timer);
		global.clearTimeout(update_view_timer);

		var oldDate = self._date,
			oldMode = self._mode;
		updateFlags(this, date, mode);

		curr_view_timer = setTimeout(function(){

			if(scheduler.$destroyed){
				return;
			}

			if (!self.callEvent("onBeforeViewChange", [oldMode, oldDate, mode || self._mode, date || self._date])){
				updateFlags(self, oldDate, oldMode);
				return;
			}

			updateView.call(self, date, mode);
			self.callEvent("onViewChange", [self._mode, self._date]);

			global.clearTimeout(update_view_timer);
			curr_view_timer = 0;
		}, scheduler.config.delay_render);
	};
	var lazy_updateView = function(date, mode){
		var self = this,
			ars = arguments;

		updateFlags(this, date, mode);

		global.clearTimeout(update_view_timer);
		update_view_timer = setTimeout(function(){
			if(scheduler.$destroyed){
				return;
			}

			if(curr_view_timer)
				return;

			updateView.apply(self, ars);
		}, scheduler.config.delay_render);
	};
	function updateFlags(scheduler, date, mode){
		if(date)
			scheduler._date = date;
		if(mode)
			scheduler._mode = mode;

	}
	scheduler.attachEvent("onSchedulerReady", function(){
		if(scheduler.config.delay_render){
			scheduler.setCurrentView = lazy_setCurrentView;
			scheduler.updateView = lazy_updateView;
		}else{
			scheduler.setCurrentView = setCurrentView;
			scheduler.updateView = updateView;
		}
	});

})();

}