export default function(scheduler){

scheduler.$keyboardNavigation.MinicalButton = function(div, index){
	this.container = div;
	this.index = index || 0;
};

scheduler.$keyboardNavigation.MinicalButton.prototype = scheduler._compose(
	scheduler.$keyboardNavigation.KeyNavNode,
	{

		isValid: function(){
			var container = this.container;
			return !!container.offsetWidth;// valid if container is visible
		},

		fallback: function(){
			var defaultSlot = new scheduler.$keyboardNavigation.TimeSlot();
			if(defaultSlot.isValid()){
				return defaultSlot;
			}else{
				return new scheduler.$keyboardNavigation.DataArea();
			}
		},
		focus: function(){
			scheduler.$keyboardNavigation.dispatcher.globalNode.disable();
			this.container.removeAttribute("tabindex");
			scheduler.$keyboardNavigation.KeyNavNode.prototype.focus.apply(this);

		},
		blur: function(){
			this.container.setAttribute("tabindex", "0");
			scheduler.$keyboardNavigation.KeyNavNode.prototype.blur.apply(this);
		},
		getNode: function(){
			if(!this.index){
				return this.container.querySelector(".dhx_cal_prev_button");
			}else{
				return this.container.querySelector(".dhx_cal_next_button");
			}
		},

		keys: {
			"right": function(e){
				this.moveTo(new scheduler.$keyboardNavigation.MinicalButton(this.container, this.index ? 0 : 1));
			},
			"left": function(e){
				this.moveTo(new scheduler.$keyboardNavigation.MinicalButton(this.container, this.index ? 0 : 1));
			},
			"down": function(){
				var next = new scheduler.$keyboardNavigation.MinicalCell(this.container, 0, 0);
				if(next && !next.isValid()){
					next = next.fallback();
				}

				this.moveTo(next);
			},
			"enter": function(e){
				this.getNode().click();
			}
		}
	}
);

scheduler.$keyboardNavigation.MinicalButton.prototype.bindAll(scheduler.$keyboardNavigation.MinicalButton.prototype.keys);

}