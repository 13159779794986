export default class LocaleManager{
	constructor(config){
		this._locales = {};
		for(const i in config){
			this._locales[i] = config[i];
		}
	}

	addLocale(name, locale) {
		this._locales[name] = locale;
	}

	getLocale(name) {
		return this._locales[name];
	}
}