import dragHighlightPos from "./drag_highlight_pos";
import undoDelete from "./undo_delete";
import limit from "./limit";
import batchUpdate from "./batch_update";

export default function initPlugins(scheduler){
    undoDelete(scheduler);
    dragHighlightPos(scheduler);
    limit(scheduler);
    batchUpdate(scheduler);
}