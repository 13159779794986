export default {
	date: {
		month_full: ["Ocak", "Þubat", "Mart", "Nisan", "Mayýs", "Haziran", "Temmuz", "Aðustos", "Eylül", "Ekim", "Kasým", "Aralýk"],
		month_short: ["Oca", "Þub", "Mar", "Nis", "May", "Haz", "Tem", "Aðu", "Eyl", "Eki", "Kas", "Ara"],
		day_full: ["Pazar", "Pazartes,", "Salý", "Çarþamba", "Perþembe", "Cuma", "Cumartesi"],
		day_short: ["Paz", "Pts", "Sal", "Çar", "Per", "Cum", "Cts"]
	},
	labels: {
		dhx_cal_today_button: "Bugün",
		day_tab: "Gün",
		week_tab: "Hafta",
		month_tab: "Ay",
		new_event: "Uygun",
		icon_save: "Kaydet",
		icon_cancel: "Ýptal",
		icon_details: "Detaylar",
		icon_edit: "Düzenle",
		icon_delete: "Sil",
		confirm_closing: "", //Your changes will be lost, are your sure ?
		confirm_deleting: "Etkinlik silinecek, devam?",
		section_description: "Açýklama",
		section_time: "Zaman aralýðý",
		full_day: "Tam gün",

		/*recurring events*/
		confirm_recurring: "Tüm tekrar eden etkinlikler silinecek, devam?",
		section_recurring: "Etkinliði tekrarla",
		button_recurring: "Pasif",
		button_recurring_open: "Aktif",
		button_edit_series: "Dizi düzenleme",
		button_edit_occurrence: "Bir kopyasını düzenleyin",

		/*agenda view extension*/
		agenda_tab: "Ajanda",
		date: "Tarih",
		description: "Açýklama",

		/*year view extension*/
		year_tab: "Yýl",

		/*week agenda view extension*/
		week_agenda_tab: "Ajanda",

		/*grid view extension*/
		grid_tab: "Izgara",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Günlük", //name="repeat" value="day"
		repeat_radio_week: "Haftalık", //name="repeat" value="week
		repeat_radio_month: "Aylık",
		repeat_radio_year: "Yıllık",
		repeat_radio_day_type: "Her",
		repeat_text_day_count: "gün",
		repeat_radio_day_type2: "Her iş günü",
		repeat_week: " Tekrar her",
		repeat_text_week_count: "hafta şu günlerde:",
		repeat_radio_month_type: "Tekrar et",
		repeat_radio_month_start: "Tarihinde",
		repeat_text_month_day: "gün her",
		repeat_text_month_count: "ay",
		repeat_text_month_count2_before: "her",
		repeat_text_month_count2_after: "ay",
		repeat_year_label: "Tarihinde",
		select_year_day2: "ayın",
		repeat_text_year_day: "günü",
		select_year_month: "ay",
		repeat_radio_end: "Bitiş tarihi yok",
		repeat_text_occurences_count: "olay",
		repeat_radio_end2: "Sonra",
		repeat_radio_end3: "Tarihinde bitir",

		repeat_never: "Asla",
		repeat_daily: "Her gün",
		repeat_workdays: "Her iş günü",
		repeat_weekly: "Her hafta",
		repeat_monthly: "Her ay",
		repeat_yearly: "Her yıl",
		repeat_custom: "Özel",
		repeat_freq_day: "Gün",
		repeat_freq_week: "Hafta",
		repeat_freq_month: "Ay",
		repeat_freq_year: "Yıl",
		repeat_on_date: "Tarihinde",
		repeat_ends: "Biter",

		month_for_recurring: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
		day_for_recurring: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
	}
};